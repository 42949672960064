@import url(https://fonts.googleapis.com/css2?family=Prompt&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Russo+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.home {
  width: 100%;
  height: 100%;

}

.menu_wrapper__mSzZH {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #dde1e7;
}
.menu_menu__3C5qA {
  position: absolute;
  bottom: -1vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 8vh;
  font-size: 1em;
  line-height: 1.2em;
  margin: auto;
  padding-bottom: 1em;
  background: #dde1e7;
}

@media (max-width: 400px) {
  .menu_menu__3C5qA {
    height: 10vh;
    bottom: -0.1vh;
    
  }
  .menu_outlet__3yHuf {
    bottom: 10vh;
    height: 80vh;
  }
}

@media (width: 800px) {
  .menu_menu__3C5qA {
    bottom: -0.1vh;
    height: 10vh;
  }
}

.menu_link__1hx8Z {
  height: 70%;
  width: 70%;
  padding: 0.1em;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.menu_outlet__3yHuf {
  position: relative;
  margin: auto;
  top: 3vh;
  font-family: 'Prompt', sans-serif;
  font-weight: bolder;
  color: rgb(3,4,94);
  height: 75vh;
  width: 99vw;
  font-size: 0.8em;
  background: #dde1e7;

}

@media (max-width: 400px) {
  .menu_outlet__3yHuf {
   
    height: 55vh;

  }
}

.menu_clock__NaXbT {
  position: relative;
  display: flex;
  width: 50%;
  margin: auto;
  font-size: 0.5em;
}

.menu_areas__1R2gd {
  color:rgb(3,4,94);
  position: relative;
  list-style: none;
  text-decoration: none;
  padding: 10px;
  margin: 3px;
  top: 1em;
  height: auto;
  width: 98vw;
  display: flex;
  flex-direction: row;
  justify-content: left;
}


@media (max-width: 400px) {
  .menu_areas__1R2gd {
    top: 1vh;
    color:rgb(3,4,94);
    flex-flow: row;
    overflow-x: scroll;
    scroll-padding:30%;
    height: auto;
    width: auto;
  }
.menu_list__3SiKa{
  max-width: -webkit-max-content;
  max-width: max-content;
  max-height: 50vh;
}

}
.menu_list__3SiKa {
  position: relative;
  list-style: none;
  text-decoration: none;
  margin: 7px;
  padding: 7px;
  overflow: scroll;
  scroll-padding: 3em;
  height: 72vh;
  min-width: 25vw;
  background: #dde1e7;
}

.menu_area__4-clJ {
  color:rgb(3,4,94);
  box-shadow: -5px -5px 9px rgba(255, 255, 255, 0.45),
    5px 5px 9px rgba(94, 104, 121, 0.3);
  text-align: center;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
}
.menu_currentArea__2UBCe{
  color:rgb(3,4,94);
  box-shadow: inset -5px -5px 9px rgba(255,255,255,0.45), inset 5px 5px 9px rgba(94,104,121,0.3);
  padding: 10px;
  margin: 5px;
  border-radius: 5px;

}






.menu_customer__hHcE7 {
  box-shadow: -5px -5px 9px rgba(255, 255, 255, 0.45),
    5px 5px 9px rgba(94, 104, 121, 0.3);
  margin: 5px;
  padding: 5px;
  border-radius: 2px;
}

.clock_clock__2AxeA {
    position: relative;
    display: flex;
    width: 50%;
    margin: auto;
    font-size: 0.4em;
  }
  
  
.topnavbar_loginout__2pEFU {
    position: absolute;
    top: 1vh;
    right: 2vh;
    z-index: 1;
    text-decoration: none;
    font-weight: lighter;
    font-family: 'Russo One', sans-serif;
    font-size: 1em;
    color: rgb(3,4,94);
}
