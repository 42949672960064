@import url('https://fonts.googleapis.com/css2?family=Prompt&display=swap');


.wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #dde1e7;
}
.menu {
  position: absolute;
  bottom: -1vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 8vh;
  font-size: 1em;
  line-height: 1.2em;
  margin: auto;
  padding-bottom: 1em;
  background: #dde1e7;
}

@media (max-width: 400px) {
  .menu {
    height: 10vh;
    bottom: -0.1vh;
    
  }
  .outlet {
    bottom: 10vh;
    height: 80vh;
  }
}

@media (width: 800px) {
  .menu {
    bottom: -0.1vh;
    height: 10vh;
  }
}

.link {
  height: 70%;
  width: 70%;
  padding: 0.1em;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.outlet {
  position: relative;
  margin: auto;
  top: 3vh;
  font-family: 'Prompt', sans-serif;
  font-weight: bolder;
  color: rgb(3,4,94);
  height: 75vh;
  width: 99vw;
  font-size: 0.8em;
  background: #dde1e7;

}

@media (max-width: 400px) {
  .outlet {
   
    height: 55vh;

  }
}

.clock {
  position: relative;
  display: flex;
  width: 50%;
  margin: auto;
  font-size: 0.5em;
}

.areas {
  color:rgb(3,4,94);
  position: relative;
  list-style: none;
  text-decoration: none;
  padding: 10px;
  margin: 3px;
  top: 1em;
  height: auto;
  width: 98vw;
  display: flex;
  flex-direction: row;
  justify-content: left;
}


@media (max-width: 400px) {
  .areas {
    top: 1vh;
    color:rgb(3,4,94);
    flex-flow: row;
    overflow-x: scroll;
    scroll-padding:30%;
    height: auto;
    width: auto;
  }
.list{
  max-width: max-content;
  max-height: 50vh;
}

}
.list {
  position: relative;
  list-style: none;
  text-decoration: none;
  margin: 7px;
  padding: 7px;
  overflow: scroll;
  scroll-padding: 3em;
  height: 72vh;
  min-width: 25vw;
  background: #dde1e7;
}

.area {
  color:rgb(3,4,94);
  box-shadow: -5px -5px 9px rgba(255, 255, 255, 0.45),
    5px 5px 9px rgba(94, 104, 121, 0.3);
  text-align: center;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
}
.currentArea{
  color:rgb(3,4,94);
  box-shadow: inset -5px -5px 9px rgba(255,255,255,0.45), inset 5px 5px 9px rgba(94,104,121,0.3);
  padding: 10px;
  margin: 5px;
  border-radius: 5px;

}






.customer {
  box-shadow: -5px -5px 9px rgba(255, 255, 255, 0.45),
    5px 5px 9px rgba(94, 104, 121, 0.3);
  margin: 5px;
  padding: 5px;
  border-radius: 2px;
}
